import Home from "../Pages/Home";
import Profile from "../Pages/Profile";
import Orders from "../Pages/Orders";
import Cart from "../Pages/Cart";
import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Auth/Login";
import VerifyCode from "../Pages/Auth/VerifyCode";
import BlogList from "../Pages/Blogs/List";
import BlogSingle from "../Pages/Blogs/Single";
import CourseSingle from "../Pages/Course/Single";
import Layout from "../Layouts";
import AuthLayout from "../Layouts/auth";
import PanelLayout from "../Layouts/panel";
import NotFound from "../Pages/NotFound";
import OrderDetailts from "../Pages/Orders/Detailts";
import ContactUs from "../Pages/ContactUs";

export const routes = [
  {
    path: "*",
    component: NotFound,
    layout: Layout,
  },
  {
    path: "/",
    component: Home,
    layout: Layout,
  },
  {
    path: "/blogs",
    layout: Layout,
    component: BlogList,
  },
  {
    layout: Layout,
    path: "/blogs/:slug",
    component: BlogSingle,
  },

  {
    layout: Layout,
    path: "/hydrographics-course",
    component: CourseSingle,
  },

  {
    layout: Layout,
    path: "/contact-us",
    component: ContactUs,
  },
  {
    layout: PanelLayout,
    path: "/dashboard",
    component: Dashboard,
  },
  {
    layout: PanelLayout,
    path: "/orders",
    component: Orders,
  },
  {
    layout: PanelLayout,
    path: "/orders/:trackingcode",
    component: OrderDetailts,
  },
  {
    layout: PanelLayout,
    path: "/profile",
    component: Profile,
  },
  {
    layout: AuthLayout,
    path: "/login",
    component: Login,
  },
  {
    layout: AuthLayout,
    path: "/verifycode",
    component: VerifyCode,
  },
];
