import React from "react";
import BlogCard from "../../Components/Cards/Blog";
import FlatList from "../../Components/FlatList";
import Paginate from "../../Components/Paginate";
import { useBlogs } from "./Hooks";

const List = () => {
  const { loading, data, setSearchParams, searchParams } = useBlogs();
  return (
    <div className="main">
      <section id="blog">
        <div className="container">
          <div className="blog-section mt-blog-9">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <div className="position-relative d-flex justify-content-center">
                  <h1 className="text-center fs-40  main-color fw-bold">
                    مقالات و آموزش‌ها
                  </h1>
                </div>

                <p
                  style={{ lineHeight: "31px" }}
                  className="text-center fs-18  fw-semi-normal secondry-color"
                >
                  آخرین و محبوب ترین مقالات در زمینه رشد کسب و کار رو ببینید و
                  یا در بانک اطلاعات سایت جستجو کنید
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-9">
                  <div className="search-box-blogs">
                    <input
                      placeholder="عبارت مورد نظر خود را جست و جو کنید ..."
                      onChange={({ target: { value } }) => {
                        setSearchParams({
                          page: searchParams.get("page") || 1,
                          title: value,
                        });
                      }}
                      defaultValue={searchParams.get("title") || ""}
                    />
                    <button>
                      <span
                        className="icon-Search"
                        style={{ verticalAlign: "middle" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-68">
              <div className="col-lg-9  ">
                <div className="row">
                  <FlatList
                    data={data.data}
                    renderItem={(item, key) => {
                      return (
                        <BlogCard loading={loading} key={key} data={item} />
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-4 d-flex justify-content-center">
                <Paginate
                  to={data.to}
                  total={data.total}
                  text="Results"
                  active={data.current_page}
                  lastPage={data.last_page}
                  pages={data.links}
                  keys={["title"]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default List;
