import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMainContext } from "../../../Context";
import { orderlist, orderdetails } from "../../../Utils/Apis";

export const useOredr = () => {
  const { post } = useMainContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ data: [] });
  const getData = async () => {
    setLoading(true);
    const { data } = await post(orderlist);
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return {
    loading,
    data,
  };
};
export const useDetails = () => {
  const { post } = useMainContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ data: [] });
  const { trackingcode } = useParams();
  const getData = async () => {
    setLoading(true);
    const { data } = await post(orderdetails, {
      trackingcode,
    });
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return {
    loading,
    data,
    trackingcode,
  };
};
