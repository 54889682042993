import { useEffect, useState } from "react";
import { useMainContext } from "../../../Context";
import { singlecource, newOrder, offCheck } from "../../../Utils/Apis";

export const useCource = () => {
  const { post, state, isLogin, dispatch } = useMainContext();
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingOff, setLoadingOff] = useState(false);
  const [data, setData] = useState({ comments: { data: [] } });
  const [form, setForm] = useState({});
  const getData = async () => {
    setLoading(true);
    const { data } = await post(singlecource, {
      cource_id: 1,
    });
    setData(data);
    setLoading(false);
  };
  const submitOrder = async () => {
    setLoadingBtn(true);
    let obj = {};
    if (isLogin()) {
      obj = {
        ...state?.user?.user,
        fullname: state?.user?.user?.name || form.fullname,
      };
    } else {
      obj = { ...form };
    }
    if (form.new_discount) {
      obj = { ...obj, off_code: form.off_code };
    }
    const { data, status } = await post(newOrder, { cource_id: 1, ...obj });
    if (status == "200" && data.url) {
      if (!isLogin()) {
        dispatch("user", data.userdata);
      }
      window.location.replace(data.url);
    }
    setLoadingBtn(false);
  };
  const submitOff = async () => {
    setLoadingOff(true);
    let obj = {};
    if (isLogin()) {
      obj = { ...state?.user?.user };
    } else {
      obj = { ...form };
    }

    const { data, status } = await post(
      offCheck,
      { cource_id: 1, ...obj, off_code: form.off_code },
      true
    );
    setForm({ ...form, ...data });

    setLoadingOff(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return {
    data,
    loading,
    submitOrder,
    form,
    setForm,
    state,
    loadingBtn,
    loadingOff,
    setLoadingOff,
    submitOff,
  };
};
