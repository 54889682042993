import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";

const Loading = ({ count = 1, col = 12, height }) => {
  const loop = () => {
    var jsx = [];
    for (let index = 0; index < count; index++) {
      jsx.push(
        <div key={index} className={`my-4 col-lg-${col}`}>
          <ContentLoader
            speed={0.5}
            backgroundColor="#eee"
            foregroundColor="#f9f9f9"
            style={{ width: "100%", height: height }}
          >
            <rect
              x="0"
              y="0"
              rx="10"
              style={{ width: "100%", height: "100%" }}
              ry="10"
              width="100%"
              height="100%"
            />
          </ContentLoader>
        </div>
      );
    }
    return jsx;
  };
  return loop();
};
export default Loading;
