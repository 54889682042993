import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Router";
import { caching } from "./Utils/functions";

const App = () => {
  useEffect(() => {
    caching();
  }, []);

  return (
    <Routes>
      {routes.map((route, count) => {
        return (
          <Route
            key={count}
            path={route.path}
            element={
              <route.layout>
                <route.component />
              </route.layout>
            }
          />
        );
      })}
    </Routes>
  );
};

export default App;
