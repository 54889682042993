import { useState } from "react";
import { useMainContext } from "../../../Context";
import { editProfile } from "../../../Utils/Apis";

export const useProfile = () => {
  const { post } = useMainContext();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState({
    profile: false,
    imageProfile: false,
  });
  const { state, dispatch } = useMainContext();
  const updateProfile = async () => {
    setLoading({ profile: true });
    const { data, status } = await post(editProfile, { ...form }, true);
    if (+status == 200) {
      dispatch("user", { ...state.user, user: data });
    }
    setLoading({ profile: false });
  };
  const updateImageProfile = async photo => {
    setLoading({ imageProfile: true });
    const { data, status } = await post(editProfile, { photo }, true);
    if (+status == 200) {
      dispatch("user", { ...state.user, user: data });
    }
    setLoading({ imageProfile: false });
  };
  return { loading, form, setForm, updateImageProfile, state, updateProfile };
};
