import React from "react";

const ImageProfileUploader = ({
  onChange,
  preview = false,
  loading = false,
}) => {
  const render = () => {
    if (loading) {
      return (
        <span className="spinner-border" role="status" aria-hidden="true" />
      );
    } else {
      if (preview) {
        return <img className="user-image" src={preview} alt="عکس پروفایل" />;
      } else {
        return (
          <img
            className="icon-image"
            src={window.origin + "/assets/img/broken/user-add.svg"}
            alt="عکس پروفایل"
          />
        );
      }
    }
  };
  return (
    <div className="image-profile-container">
      <input
        type={"file"}
        className="d-none"
        id="imagePicker"
        onChange={({ target: { files } }) => {
          onChange(files);
        }}
      />
      <label htmlFor="imagePicker" className="image-profile">
        {render()}
      </label>
    </div>
  );
};

export default ImageProfileUploader;
