import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMainContext } from "../../../Context";
import { post } from "../../../Server";
import { login, verfiyCode } from "../../../Utils/Apis";

export const useLogin = () => {
  const [form, setForm] = useState({ mobile: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const submit = async () => {
    setLoading(true);
    const { data, status } = await post(login, form, true);
    if (+status == 200) {
      navigate("/verifycode", { state: { ...form } });
    }
    setLoading(false);
  };

  return { form, setForm, submit, loading };
};
//
export const useVerfiyCode = () => {
  const { state } = useLocation();
  const [form, setForm] = useState({ mobile: state.mobile, code: "" });
  const [loading, setLoading] = useState(false);
  const { dispatch } = useMainContext();
  const navigate = useNavigate();
  const submit = async () => {
    setLoading(true);
    const { data, status } = await post(verfiyCode, form, true);
    if (+status == 200) {
      dispatch("user", data);
      navigate("/dashboard");
    }
    setLoading(false);
  };

  return { form, setForm, submit, loading };
};
