import React from "react";
import { p2e } from "../../Utils/functions";
import { useVerfiyCode } from "./Hooks";

const VerifyCode = () => {
  const { form, setForm, submit, loading } = useVerfiyCode();
  return (
    <div className="main" id="Register-page">
      <div className="register-box">
        <div className="logo-box text-center">
          <img src="assets/img/logo.png" alt="LOGO" />
        </div>
        {/* Box Description */}
        <p className=" text-blue mt-4 text-center">
          کد ورود پیامکی دریافتی را وارد نمایید
        </p>
        {/* ./ Box Description */}
        {/* Form */}
        <div>
          <div className="input-form mt-4">
            <label htmlFor="number">
              <img src="assets/img/broken/shield-tick.svg" alt="tel" />
              {/* Input */}
              <input
                type="tel"
                name="number"
                id="number"
                required
                autoFocus
                onChange={({ target: { value } }) => {
                  setForm({ ...form, code: p2e(value) });
                }}
                onKeyDown={e => {
                  if (e.key == "Enter") {
                    submit();
                  }
                }}
                style={{
                  letterSpacing: "1.25em",
                }}
              />
            </label>
          </div>
          <button
            className="submit-btn"
            name="send"
            id="submit"
            disabled={loading || form.mobile.length !== 11}
            onClick={submit}
          >
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            ) : (
              "تایید"
            )}
          </button>
        </div>
        {/* ./ Form */}
      </div>
    </div>
  );
};

export default VerifyCode;
