import React from "react";
import { Link } from "react-router-dom";
import { cutText, timeSince } from "../../../Utils/functions";
import Loading from "../../Loading";

const BlogCard = ({ loading, data }) => {
  if (loading) {
    return (
      <div className="col-lg-4 col-md-6 mb-30">
        <div className="blog-item">
          <Loading height={"390"} />
        </div>
      </div>
    );
  }
  return (
    <div className="col-lg-4  col-md-6  mb-30">
      <Link className="blog-item" to={`${data.slug}`}>
        <span className="category-badge">{data?.category?.title}</span>
        <div className="blog-img">
          <img
            src={
              (data?.image[0] && data?.image[0].url) ||
              window.origin + "/assets/img/blog/item-1.jpg"
            }
            alt={(data?.image[0] && data?.image[0].url) || data.title}
          />
          <div className="overlay-image-blog"></div>
        </div>
        <div className="blog-content">
          <span className="d-block text-blue ">{data.title}</span>
          <p className="fs-16 text-blue-sc ">
            {cutText(data.description, 105)}
          </p>
          <div className="blog-footer ">
            <div className="row justify-content-between">
              <div className="col-6 col-sm-6 col--12 col-xl-6">
                <div className="d-flex align-items-stretch">
                  <div className="author-avatar align-items-end">
                    <img
                      src={data?.auther?.photo || "assets/img/user/Avatar.png"}
                      alt="IMG"
                    />
                  </div>
                  <div className="user-info ms-3">
                    <p className="fs-15 text-blue mb-0">
                      {data?.auther?.fullname}
                    </p>
                    <span className="text-blue-sc fs-12">نویسنده</span>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-6 col--12 col-xl-6 d-flex justify-content-end">
                <div className="blog-meta d-flex ">
                  {/* date */}
                  <p className="d-flex align-items-center mb-0">
                    <img
                      src="assets/img/broken/calendar.svg"
                      alt="زمان مقاله"
                    />
                    <span className="text-blue-sc fs-10 ms-1">
                      {timeSince(data.created_at)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
