import moment from "jalali-moment";
import packageJson from "../../package.json";

export const timeSince = date => {
  if (date) {
    let data = new Date(date);

    let seconds = Math.floor((new Date() - data) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " سال پیش";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " ماه پیش";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " روز پیش";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " ساعت پیش";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " دقیقه پیش";
    }
    return "اخیرا";
  } else {
    return "none";
  }
};
export const cutText = (txt, length = 20) => {
  if (txt) {
    return `${txt.slice(0, length)} ...`;
  }
};
export const ToDateJalali = date => {
  if (date) {
    return moment(date).locale("fa").format("YYYY/MM/DD");
  } else {
    return null;
  }
};
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};
export const p2e = s => s.replace(/[۰-۹]/g, d => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
// export const renderPrice = (price = "") => {
//   let res = "";
//   `${"233442344"}`.split("").map((element, count) => {
//     if ((count + 1) % 3 == 0) {
//       res += "/" + element;
//     } else {
//       res += element;
//     }
//   });
//   return res;
// };
export const renderPrice = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "/");
};
export const caching = () => {
  let version = localStorage.getItem("version");
  if (version != packageJson.version) {
    if ("caches" in window) {
      caches.keys().then(names => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }

    localStorage.clear();
    localStorage.setItem("version", packageJson.version);
  }
};
