import React, { useRef } from "react";
import { Link } from "react-router-dom";
import FreeMoshavereh from "../../Components/FreeMoshavereh/index";
import { useLanding } from "./hooks";
const Home = () => {
  const { data, loading } = useLanding();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <div className="main landing">
      <section className="">
        <div className="container mt-9 ">
          <div className="row landing-hero">
            <div className="hero-info-nima col-lg-7 d-flex justify-content-center flex-column order-lg-0 order-2 mt-lg-0 mt-3 ">
              <h3 className="fs-18">محقق و کارآفرین</h3>

              <h1 className="fs-40 fw-bolder mb-lg-0 mb-2">نیما کوچکی</h1>
              <p className="fs-18 mw-500 mt-9p">
                من نیما کوچکی از سال ۱۳۹۴ وارد حوزه کارآفرینی شدم و با توجه به
                نیاز جامعه که شغل بود تصمیم به راه‌اندازی یک تیم تحقیق و توسعه
                در حوزه جستجو و معرفی به‌روزترین مهارت‌های دنیا گرفتم که به کمک
                این تیم موفق به یافتن شغل‌هایی پولساز برای جوانان شدیم.
              </p>
              <div className="mw-500 d-flex justify-content-center">
                <button className="go-bottom" onClick={executeScroll}>
                  <span className="icon-DownArrow" />
                </button>
              </div>
            </div>
            <div className="col-lg-5 position-relative order-lg-0 order-1">
              <img
                style={{ width: "100%", borderRadius: "35px" }}
                src={
                  window.innerWidth > 992
                    ? window.origin + "/assets/img/landing/nima2.jpeg"
                    : "/assets/img/landing/nima-mobile.jpeg"
                }
              />
              <div className="sabeghe d-none d-lg-flex">
                <div className="sabeghe-icon">
                  <span
                    className="icon-Calender"
                    style={{ fontSize: "2rem" }}
                  />
                </div>
                <p>بیش از ۸ سال سابقه</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-77" ref={myRef}>
            <div className="col-10">
              <p className="text-justify text-center pppp fs-18">
                با توجه به اینکه دغدغه جوانان یافتن مهارت‌هایی پولساز با سرمایه
                اولیه نه چندان زیاد بود، تصمیم به معرفی مشاغلی گرفتیم که اکثریت
                جامعه بتونن بت حداقل سرمایه اولیه و گاها با سرمایه‌ای کمتر از ۱۰
                میلیون تومان صاحب کسب و کار شخصی خودشون بشن.چون داشتن مهارت در
                هرکدام از این حوزه‌ها اهمیت پیدا می‌کرد تصمیم گرفتیم علاوه بر
                معرفی، آموزش صفر تا صد این مشاغل رو ارائه بدیم.
              </p>
              <div></div>
              <div className="row justify-content-center">
                <div className="col-sm-10 ">
                  <div className="row">
                    {[
                      {
                        icon: () => {
                          return <span className="icon-HomeDesign w-42" />;
                        },
                        title: "دکوراسیون داخلی",
                      },
                      {
                        icon: () => {
                          return (
                            <span className="icon-Car w-42">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                              <span className="path6"></span>
                              <span className="path7"></span>
                              <span className="path8"></span>
                            </span>
                          );
                        },
                        title: "خودرو",
                      },
                      {
                        icon: () => {
                          return <span className="icon-Hydrography w-42" />;
                        },
                        title: "هیدروگرافیک",
                      },
                      {
                        icon: () => {
                          return <span className="icon-More w-42" />;
                        },
                        title: "و ...",
                      },
                    ].map((item, key) => {
                      return (
                        <div
                          className="col-lg-3 col-6  mb-md-3 mb-3 mb-lg-0"
                          key={key}
                        >
                          <div className="service-card">
                            <div className="icon-service-card">
                              {item.icon()}
                            </div>
                            <div className="icon-service-text">
                              <p
                                className="fs-15"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {item.title}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2
                style={{ textAlign: "center", marginTop: "116px" }}
                className="fw-bold dark-blue fs-40"
              >
                دوره هیدروگرافیک
              </h2>
              <p
                className="light-blue fw-semi-normal fs-18 mt-9p"
                style={{ textAlign: "center" }}
              >
                مجموعه نیما کوچکی ارائه دهنده دوره هیدروگرافیک به عنوان یکی از
                به روزترین و پولسازترین مهارتهای شغلی دنیا
              </p>
              <div className="d-flex justify-content-center position-relative  video-landing-wrapper">
                <img
                  className=" "
                  style={{ width: "100%" }}
                  src={window.origin + "/assets/img/landing/hidrography.png"}
                ></img>
                {/* <div className="overlay-video-play">
                  <div>
                    <img
                      src={
                        window.origin + "/assets/img/landing/play-circle.png"
                      }
                    />
                  </div>
                  <p>دوره هیدروگرافیک چیست؟</p>
                </div> */}
              </div>
              <div className="d-flex justify-content-center">
                <Link to={"/hydrographics-course"} className="btn-more fs-14">
                  کسب اطلاعات بیشتر
                </Link>
              </div>
            </div>
          </div>
          <div
            className="row third-section justify-content-center"
            style={{ marginTop: "140px" }}
          >
            <div className="col-xl-5 col-lg-6 d-none d-lg-block">
              <div
                className="d-flex align-items-center right-sider-wrapper"
                style={{ gap: "17px" }}
              >
                <div className="right-side-third">
                  <div className="third-card">
                    <div>
                      {loading ? (
                        <>loading</>
                      ) : (
                        <span> {data.count_consultation} +</span>
                      )}
                    </div>
                    <p>مشاوره و شخصیت‌‌‌شناسی</p>
                  </div>
                  <div className="third-card movafhagh-card">
                    <div>
                      {loading ? (
                        <>loading</>
                      ) : (
                        <span> {data.count_success_business} +</span>
                      )}
                    </div>
                    <p>هنرجو موفق</p>
                  </div>
                </div>
                <div className="left-side-third">
                  <div className="third-card">
                    <div>
                      {loading ? (
                        <>loading</>
                      ) : (
                        <span> {data.count_expertـconsultant} +</span>
                      )}
                    </div>
                    <p>هنرجو هیدروگرافیک</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 d-flex justify-content align-items-center">
              <div style={{}}>
                <h4 className="fw-bold-light fs-25 dark-blue">
                  مشاوره و شخصیت شناسی شغلی
                </h4>
                <p
                  className="fs-18 text-justify fw-semi-normal light-blue mt-9p"
                  style={{
                    maxWidth: "540px",
                    textAlign: "justify",
                    lineHeight: "31px",
                  }}
                >
                  اما دغدغه دیگری که وجود داشت، عدم اطمینان از شغل مناسب برای
                  جوانان هنگام ورود به بازار کار بود. بنابراین در گامی دیگر به
                  کمک متخصصین آزمونی جهت تیپ‌شناسی طراحی کردیم و با بررسی دقیق
                  نتایج، شغل مناسب هر شخص را به او معرفی می‌کردیم.
                  <br />
                  با انجام تست شخصیت‌شناسی و مشاوره تخصصی هر شخص به محیط مناسب
                  با روحیات خود ورود می‌کرد که موجب نتایج بسیار دلچسب شد.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-lg-4 my-4">
            <div className="col-12">
              <p
                className="light-blue fs-18 secondry-color  fw-semi-normal "
                style={{ textAlign: "center", lineHeight: "31px" }}
              >
                مجموعه <span className="fs-18 fw-semibold">نیما کوچکی</span> به
                عنوان اولین و تخصصی‌ترین مرکز آموزش و مشاوره شغلی به شما کمک
                میکنه تا بتونین استعداد خودتون رو بشناسین <br />و وارد بازار کار
                مورد نظر خودتون بشین.
                <br /> این کار به کمک یک فرآیند کاملا تخصصی و متناسب با شرایط
                کشور صورت می‌گیره، این مجموعه از ابتدای مسیر طی آموزش های مختلف
                ابتدا
                <br />
                شما رو با تیپ شخصیت کاری خودتون آشنا و در نهایت شما رو به یک
                حوزه ناب و بکر شغلی معرفی می‌کنه در نتیجه
                <br />
                <span className="fs-18 fw-semibold">
                  و شما بدون کوچک ترین نگرانی وارد بازار کار میشی ...
                </span>
              </p>
            </div>
          </div>
          <FreeMoshavereh />
        </div>
      </section>
    </div>
  );
};

export default Home;
