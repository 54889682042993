import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { post } from "../../../Server";
import { blogs, blog } from "../../../Utils/Apis";

export const useBlogs = () => {
  const [form, setForm] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ data: [] });
  const { search } = useLocation();
  const getData = async () => {
    setLoading(true);
    const { data, status } = await post(blogs, {
      page: searchParams.get("page") || 1,
      search: searchParams.get("title"),
    });
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [search]);

  return {
    form,
    setForm,
    getData,
    loading,
    data,
    setSearchParams,
    searchParams,
  };
};
export const useBlog = () => {
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const [data, setData] = useState({ blog: {}, commnets: {} });
  const getData = async () => {
    setLoading(true);
    const { data, status } = await post(blog, { slug });
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    if (!slug) return;
    getData();
  }, [slug]);

  return {
    getData,
    loading,
    data,
  };
};
