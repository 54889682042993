import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useMainContext } from "../../Context";
import { search } from "../../Utils/Apis";

export const useSearch = () => {
  const [form, setForm] = useState({
    serach: "",
    show: false,
    nav: false,
    drawer: false,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { pathname } = useLocation();
  const { state, post } = useMainContext();
  const getData = async (serach = "") => {
    setLoading(true);
    const { data, status } = await post(search, { serach });
    console.log("da", data);
    setData(data || []);
    setLoading(false);
  };
  const toggle = () => {
    setForm({ ...form, show: !form.show });
  };
  useEffect(() => {
    setForm({ show: false });
  }, [pathname]);

  return {
    form,
    setForm,
    getData,
    loading,
    data,

    toggle,
    getData,
    state,
  };
};
