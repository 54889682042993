import React from "react";
import { Link, useSearchParams } from "react-router-dom";
const Paginate = ({ pages = [], active, keys = [] }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const renderLink = page => {
    let res = "?";
    keys.map((key, count) => {
      res =
        res +
        `${key}=${searchParams.get(key) || ""}${key - 1 == count ? "" : "&&"}`;
    });
    return res + "page=" + page;
  };
  renderLink();
  return (
    <div className="paginate">
      {pages.map(page => {
        return (
          <Link
            to={renderLink(page.label)}
            className={`${page.label == active && "active"}`}
            style={{padding:'10pxs'}}
          >
            {page.label}
          </Link>
        );
      })}
    </div>
  );
};

export default Paginate;
