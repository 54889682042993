import React from "react";
import Loading from "../../Components/Loading";
import { ToDateJalali } from "../../Utils/functions";
import { useDashboard } from "./Hooks";

const Dashboard = () => {
  const { loading, data, user } = useDashboard();
  console.log("d", data);
  return (
    <div id="main-content" className="col-lg-9">
      <div className="user-info container">
        {/* User Title */}
        <div className="info-title d-flex">
          <img src="assets/img/broken/barcode.svg" alt="/" />
          <p className="mb-0 text-white fs-16">پیشخوان</p>
        </div>
        {/* Dashboard Content */}
        <div className="info-content">
          <div className="row d-flex align-items-center">
            <div className="col-lg-9">
              <p className="mb-0 text-blue fs-18">
                {user?.user?.fullname ?? "کاربر"} عزیز، به پنل کاربری خوش آمدید.
              </p>
              <p className="text-blue fs-18">
                برای دریافت{" "}
                <a href className="text-yellow">
                  لایسنس دوره‌های خریداری شده{" "}
                </a>{" "}
                خود به قسمت سفارشات من مراجعه فرمایید.
              </p>
              <p className="text-blue fs-18">
                {" "}
                توجه داشته باشید که دوره‌ها بر بستر اسپات پلیر ارائه می‌شود و
                امکان استفاده از هر لایسنس برای دو دستگاه فراهم می‌باشد.{" "}
              </p>
            </div>
            <div className="col-lg-3 text-center">
              <img
                src="assets/img/dashboard/Screen%20Shot%201401-07-09%20at%2012.10%201.png"
                alt="/"
              />
            </div>
          </div>
        </div>
        {/* User Title */}
        <div className="info-title d-flex">
          <img src="assets/img/broken/barcode.svg" alt="/" />
          <p className="mb-0 text-white fs-16">اطلاعیه‌ها</p>
        </div>
        {/*Info Img*/}
        {loading ? (
          <Loading height={"150"} />
        ) : data.length == 0 ? (
          <>
            <div className="info-img text-center mt-5 mb-4">
              {" "}
              <img src="assets/img/dashboard/Empty%20Icon.png" alt />
              <span className="d-block text-blue-sc mt-3">
                اطلاعیه جدیدی یافت نشد
              </span>
            </div>
          </>
        ) : (
          data.map(notif => {
            return (
              <div className="d-flex align-items-center justify-content-between p-10 flex-wrap ">
                <div className="w-80 notif-wrapper " style={{ width: "90%" }}>
                  <p
                    className="fs-18"
                    style={{
                      wordWrap: "break-word",
                      margin: 0,
                    }}
                  >
                    {notif.content}
                  </p>
                </div>
                <div className="d-flex justify-content-end">
                  <span className="fs-18" style={{}}>
                    {ToDateJalali(notif.created_at)}
                  </span>
                </div>
              </div>
            );
          })
        )}
        <div className="mb-3"></div>
      </div>
    </div>
  );
};

export default Dashboard;
