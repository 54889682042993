import { useEffect, useState } from "react";
import { useMainContext } from "../../../Context";
import { landing } from "../../../Utils/Apis";

export const useLanding = () => {
  const { post } = useMainContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const getData = async () => {
    setLoading(true);
    const { data } = await post(landing);
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return {
    data,
    loading,
  };
};
