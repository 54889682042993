import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDetails } from "./Hooks";
const OrderDetailts = () => {
  const { data, loading, trackingcode } = useDetails();
  const apps = [
    {
      icon: window.origin + "/assets/img/dashboard/windows.png",
      title: "Windows",
      version: "5.3.1.31",
      link: "https://app.spotplayer.ir/assets/bin/spotplayer/setup.exe",
    },
    {
      icon: window.origin + "/assets/img/dashboard/mac.png",
      title: "MacOS",
      version: "5.3.1.31",
      link: "https://app.spotplayer.ir/assets/img/platform/mac.png",
    },
    {
      icon: window.origin + "/assets/img/dashboard/android.png",
      title: "Android",
      version: "5.2.0.28",
      link: "https://app.spotplayer.ir/assets/bin/spotplayer/setup.apk",
    },
    {
      icon: window.origin + "/assets/img/dashboard/chrome.png",
      title: "Web App",
      version: "5.0.3.24",
      link: "https://app.spotplayer.ir/",
    },
    {
      icon: window.origin + "/assets/img/dashboard/obunto.png",
      title: "Ubuntu",
      version: "(به زودی)",
      link: "",
    },
    {
      icon: window.origin + "/assets/img/dashboard/ios.png",
      title: "IOS",
      version: "(به زودی)",
      link: "",
    },
  ];
  return (
    <div id="main-content" className="col-lg-9 order-details">
      <div className="user-info container">
        <div className="info-title d-flex">
          <img src={window.origin + "/assets/img/broken/barcode.svg"} alt="/" />
          <p className="mb-0 text-white fs-16">{`جزئیات سفارش ${trackingcode}#`}</p>
        </div>
        <div className="info-content">
          <div className="row">
            <div className="col-lg-12">
              <p className="fw-semi-normal fs-12 text-blue ">
                سفارش شما با موفقیت ثبت شده است، جهت مشاهده دوره خریداری شده،
                باید از نرم افزار اسپات پلیر (SpotPlayer) استفاده شود.
              </p>
              <ul>
                <li className="fw-semi-normal fs-12 text-blue ">
                  اگر با نحوه استفاده از این نرم افزار آشنایی دارید از طریق
                  لینک‌های زیر نسخه آخر مربوط به نرم افزار متناسب با سیستم عامل
                  خود را دریافت کرده و کد لایسنس را در نرم افزار قرار دهید.
                </li>
                <li className="fw-semi-normal fs-12 text-blue ">
                  اگر با نحوه استفاده از نرم افزار آشنایی ندارید می‌توانید ضمن
                  دانلود نرم افزار از لینک‌های زیر،{" "}
                  <a
                    href="https://app.spotplayer.ir/player/help/"
                    className="text-yellow"
                  >
                    اینجا
                  </a>{" "}
                  <a
                    target={"_blank"}
                    href="https://app.spotplayer.ir/player/help/"
                  >
                    کلیک
                  </a>{" "}
                  کنید.
                </li>
              </ul>
              <p className="fw-semi-normal fs-12 text-blue ">
                <span className="text-yellow"> توجه </span>
                داشته باشید که این کد لایسنس فقط بر روی
                <span className="text-yellow"> یک دستگاه </span>
                قابل استفاده خواهد بود.
              </p>
            </div>
          </div>
          <div className="row justify-content-center mb-27">
            <div className="col-lg-10 ">
              <div className="license-card">
                <div className="d-none d-md-inline">
                  <span className="badge-license">لایسنس شما</span>
                </div>
                <div>
                  <p>{data.lisene}</p>
                </div>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(data.lisene);
                    toast.success("لایسنس با موفقیت کپی شد");
                  }}
                >
                  <img
                    alt="کد لایسنس"
                    src={window.origin + "/assets/img/broken/document-copy.svg"}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center app-container">
            {apps.map(app => {
              return (
                <div className="col-lg-1 col-4 text-center">
                  <a target={"_blank"} href={app.link} className="app-card">
                    <img src={app.icon} />
                    <div>
                      <p>{app.title}</p>
                      <p>{app.version}</p>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailts;
