import React, { useEffect } from "react";
import Comments from "../../Components/Comments";
import FreeMoshavereh from "../../Components/FreeMoshavereh";
import Loading from "../../Components/Loading";
import { renderPrice } from "../../Utils/functions";
import { useCource } from "./hooks";
import { ReactComponent as Icon } from "./half-star.svg";

import "./index.css";
const Single = () => {
  const {
    data,
    loading,
    form,
    setForm,
    state,
    submitOrder,
    loadingBtn,
    loadingOff,
    submitOff,
  } = useCource();
  const handleClickScroll = () => {
    const element = document.getElementById("pay");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    if (window.location.hash == "#pay" && !loading) {
      handleClickScroll();
    }
  }, [window.location.hash, loading]);

  if (loading) {
    return (
      <div className="main course">
        <div className="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="main course">
      <section className="">
        <div className=" container  mt-9">
          <div className="container">
            <div className="row course-hero justify-content-center">
              <div
                className="info-hero col-lg-6 d-flex justify-content-center flex-column"
                style={{ paddingRight: "30px" }}
              >
                <div
                  style={{
                    margin: "0 40px",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "2px" }}
                  >
                    {Array(5)
                      .fill(1)
                      .map((_i, counter) => {
                        if (
                          data?.cource?.comments_avg_rate &&
                          Math.ceil(data?.cource?.comments_avg_rate)
                        ) {
                          if (counter <= data.comments_avg_rate) {
                            return (
                              <>
                                <span
                                  key={counter}
                                  className="icon-Star"
                                  data-status={counter == 0}
                                ></span>
                              </>
                            );
                          }
                        } else {
                          return (
                            <>
                              <span
                                key={counter}
                                className="icon-Star"
                                // data-status={counter == 0}
                              ></span>
                            </>
                          );
                        }
                      })}
                    {/* <Icon /> */}

                    <p className="score-result">({data.comments.total})نظر</p>
                  </div>
                  <h1 className="fs-40 title-course">دوره هیدروگرافیک</h1>
                  <p className="mw-500 fs-18">
                    هیدروگرافیک به عنوان یکی از روزترین و پولسازترین مهارتهای
                    شغلی دنیا با توجه به گستردگی فعالیت و همچنین قابلیت اجرا بر
                    روی تمامی سطوح (پلاستیک، شیشه، فایبرگلاس، پارچه، چوب، سرامیک
                    و …) مورد استقبال تمامی صنایع و همچنین عموم مردم قرار گرفته
                    است.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-6 position-relative"
                style={{
                  paddingLeft: "50px",
                }}
              >
                <img
                  style={{
                    maxWidth: "422px",
                    maxHeight: "490px",
                  }}
                  src={window.origin + "/assets/img/landing/nima.png"}
                />
                {/* <div className="additional-photo">
                  <img src={window.origin + "/assets/img/blog/item-1.jpg"} />
                  <img src={window.origin + "/assets/img/blog/item-1.jpg"} />
                  <img src={window.origin + "/assets/img/blog/item-1.jpg"} />
                </div> */}
              </div>
            </div>
            <div className="row what-why">
              <div className="col-lg-6">
                <div className="what-card">
                  <div className="what-icon">
                    <span className="icon-Question">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </span>
                  </div>
                  <div className="what-title">
                    <h3 className="fs-25">هیدروگرافیک چیست؟</h3>
                  </div>
                  <div className="what-text fs-18">
                    <p>
                      هیدروگرافیک یا چاپ آبی، یک فرآیند دکوراتیو سه بعدی
                      می‌باشد، که برای اولین بار حدود ۴۰ سال پیش در کشور آمریکا
                      شناخته شد. این فرایند صنعت چاپ را متحول کرده و جایگزین
                      بسیاری از فرآیندهای چاپ در حوزه های مختلف گردیده است.
                      <br />
                      هیدروگرافیک به کمک تجهیزاتی صورت می‌گیرد که به دستگاه
                      هیدروگرافیک مشهور است. البته با نام‌های دیگری چون دستگاه
                      تصویربرداری آبی و دستگاه چاپ مکعب نیز معروف است.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 left-side-mobile">
                <div className="what-card " style={{ marginTop: "160px" }}>
                  <div className="what-icon">
                    <span className="icon-ProfitList">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                    </span>
                  </div>
                  <div className="what-title">
                    <h3 className="fs-25">مزیت‌های هیدروگرافیک چیست؟</h3>
                  </div>
                  <div className="what-ul">
                    <ul>
                      <li className="fs-18">
                        از مهم ترین مزایای چاپ هیدروگرافیک این است که روی هر
                        سطحی که در تماس با آب دچار آسیب نشود، قابلیت اجرایی با
                        کیفیت فوق العاده بالا را دارد.
                      </li>
                      <li className="fs-18">
                        اگرچه دستگاه‌های چاپ با قیمت‌های بسیار گزاف و سنگینی در
                        سطح بازار وجود دارند، اما برای چاپ‌های هیدروگرافیک می
                        توان با سرمایه‌ای بسیار اندک و بدون نیاز به هیچ‌گونه
                        فضای کارگاهی ( و حتی در منزل) مشغول به فعالیت شد.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "60px" }}
              className="row hidro-video justify-content-center"
            >
              <div className="col-lg-8">
                <div className="video-wrapper">
                  <video
                    poster={
                      window.origin + "/assets/img/landing/nima-mobile.jpeg"
                    }
                    src="https://api.nimakouchaki.com/hydro/hydro.mp4"
                    controls
                    style={{ marginBottom: "76px", borderRadius: "15px" }}
                  />
                </div>
              </div>
            </div>

            <div className="for-who row">
              <div className="col-lg-10">
                <div className="for-who-title">
                  <span className="icon-People">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </span>
                  <h4 className="fs-25">این دوره برای چه کسانی مناسب است؟</h4>
                </div>
                <div className="for-who-text fs-18">
                  <p className="fs-18">
                    مهارت هیدروگرافیک بر خلاف سایر مهارت‌ها، برای علاقمندان به
                    هر حوزه‌ای مناسب می‌باشد. به طور کلی می‌توان گفت مهارت
                    هیدروگرافیک برای این دسته از عزیزان می‌تواند مناسب باشد:
                  </p>
                  <ul>
                    <li className="fs-18">
                      آقا و یا خانمی (بدون محدودیت سنی) که قصد شروع فعالیت و کسب
                      درآمد دارند!
                    </li>
                    <li className="fs-18">
                      افرادی که تمایل به راه‌اندازی کسب و کار شخصی خودشان دارند!
                    </li>
                    <li className="fs-18">
                      افرادی که قصد سرمایه گزاری اولیه بالایی ندارند !
                    </li>
                    <li className="fs-18">
                      افرادی که قصد فعالیت در محیط مسکونی و منزل خود را دارند!
                    </li>
                    <li className="fs-18">و...</li>
                  </ul>
                </div>
              </div>
            </div>
            <FreeMoshavereh
              title={() => {
                return (
                  <>
                    <h4>
                      نیاز به شناخت بیشتری دارید؟ یه مشاوره
                      <span> رایگان </span>
                      مهمون ما باشید!
                    </h4>
                  </>
                );
              }}
            />
            <div
              className="row chapters  "
              style={{
                marginTop: "92px",
              }}
            >
              <div className="col-lg-6">
                <div className="chapters-title ">
                  <span class="icon-List">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                  </span>
                  <h4 className="fs-25">سرفصل‌های دوره هیدروگرافیک</h4>
                </div>
                <div className="chapters-text">
                  <ul>
                    <li className="fs-18">
                      آشنایی با دیپ زدن انواع قطعات دایره ای و منحنی و تخت در
                      هیدروگرافیک
                    </li>
                    <li className="fs-18">آشنایی با نحوه کیلر پاشی</li>
                    <li className="fs-18">
                      آشنایی با انواع پیستوله و نحوه کار کردن با آنها
                    </li>
                    <li className="fs-18">
                      شناخت زاویه ها در هیدروگرافیک برای دیپ زدن
                    </li>
                    <li className="fs-18">
                      عیب یابی و اصلاح قطعات خراب شده در هیدروگرافیک
                    </li>
                    <li className="fs-18">
                      نحوه ساختن فیکسچر برای قطعات با تیراژ بالا
                    </li>
                    <li className="fs-18">
                      نحوه خشک کردن قطعات بعد از هیدروگرافیک
                    </li>
                    <li className="fs-18">
                      نحوه صحیح شستشو قطعات بعد از هیدروگرافیک
                    </li>
                    <li className="fs-18">
                      نحوه اجرای قطعات سری و تیراژ بالا اموزش ساخت میز گردان رنگ
                    </li>
                    <li className="fs-18">
                      نحوه پهن کردن فیلم هیدروگرافیک و حباب گیری
                    </li>
                    <li className="fs-18">
                      {" "}
                      آموزش ساخت رنگ های ترکیبی زیر کار هیدروگرافیک
                    </li>
                    <li className="fs-18">
                      نحوه تنظیم و پاشش اکتیویتور روی قطعات کوچک و بزرگ
                    </li>
                    <li className="fs-18">
                      آموزش نحوه کاور کردن قطعات قبل و بعد از هیدروگرافیک
                    </li>
                    <li className="fs-18">آموزش بازاریابی و اصول فروش</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 online-course-img align-items-center d-flex"
                align="center"
              >
                <img
                  style={{ width: "100%" }}
                  src={window.origin + "/assets/img/course/offline.png"}
                />
                <p>دوره به صورت آنلاین ارائه می‌شود.</p>
              </div>
            </div>
            <div
              className="row why-nima justify-content-center"
              style={{ marginBottom: "120px" }}
            >
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center">
                  <h4 className="fs-25">
                    چرا مجموعه نیما کوچکی رو انتخاب کنید
                    <span> !؟ </span>
                  </h4>
                </div>

                <div className="d-flex justify-content-center">
                  <p className="fs-18">
                    مجموعه آموزشی نیما کوچکی با برگزاری بیش از ۸۰ دوره آموزش
                    حضوری هیدروگرافیک و آموزش بیش از ۵۰۰ هنرجو، به دلیل تقاضا
                    فراوان هنرجویان از سراسر کشور و همچنین کاهش اتلاف وقت و
                    هزینه دانشجویان اقدام به تولید پکیج آموزش غیر حضوری
                    هیدروگرافیک نمود.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-10 d-flex justify-content-center"
                style={{ gap: "20px" }}
              >
                {[
                  {
                    title: "پشتیبانی مادام‌العمر آموزشی",
                    subtitle: [
                      "دریافت بروزرسانی دوره",
                      "امکان ارتباط با استاد و رفع اشکال ",
                    ],
                    icon: () => {
                      return <span className="icon-Educate w-42" />;
                    },
                  },
                  {
                    title: "پشتیبانی مادام‌العمر تأمین متریال",
                    subtitle: [
                      "ارائه و ارسال متریال مصرفی",
                      "ارائه دستگاه‌های مورد نیاز",
                    ],
                    icon: () => {
                      return <span className="icon-Infinity w-42" />;
                    },
                  },
                  {
                    title: "معرفی به بازار کار",
                    subtitle: [
                      "ارتباط هنرجویان فعال با صنایع",
                      "ایجاد ارتباط بدون واسطه با مشتری",
                    ],
                    icon: () => {
                      return <span className="icon-Asset-65 w-42" />;
                    },
                  },
                  {
                    title: "دسترسی سریع در دو گام",
                    subtitle: [
                      "ثبت نام و تکمیل سفارش",
                      "استفاده از لایسنس و فعالسازی",
                    ],
                    icon: () => {
                      return <span className="icon-Flash w-42" />;
                    },
                  },
                ].map((item, count) => {
                  return (
                    <div className="why-nima-card">
                      <div className="why-nima-card-icon">
                        <div>{item.icon()}</div>
                      </div>
                      <div className="why-nima-card-title">
                        <h5 className="fs-15">{item.title}</h5>
                      </div>
                      <div className="why-nima-card-ul">
                        <ul>
                          {item.subtitle.map((li, count) => {
                            return (
                              <li className="" count={li}>
                                {li}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row justify-content-center mt-lg-5 mt-4 mb-lg-4 mb-4">
              <div className="col-lg-7 col-md-10">
                {loading ? (
                  <Loading col={12} height={"400px"} />
                ) : (
                  <div className="join-course" id="pay">
                    <div className="overlay-join-course">
                      <div className="join-course-box">
                        <div className="join-course-box-title">
                          <h3 className="fs-28">
                            ثبت‌نام در دوره تخصصی آموزش هیدروگرافیک
                          </h3>
                          <div
                            className="d-flex justify-content-center"
                            style={{ gap: "21px" }}
                          >
                            <h4 className="fs-25">مبلغ سرمایه‌گذاری</h4>
                            <div>
                              <h4
                                className={`fs-25 ${
                                  form.new_discount ? "off-code-price" : ""
                                }`}
                              >
                                {data?.cource?.second_price?.price
                                  ? renderPrice(
                                      data?.cource?.second_price?.price
                                    )
                                  : renderPrice(data.cource.price)}
                                تومان
                              </h4>

                              {form.new_discount && (
                                <h4 className="fs-25 new-price">
                                  {renderPrice(form.new_total_price)} تومان
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                        {form.show ? (
                          <></>
                        ) : (
                          <>
                            <div className="join-course-box-offer fs-15 ">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setForm({ ...form, show: true });
                                }}
                              >
                                (کد تخفیف دارید؟)
                              </a>
                            </div>
                          </>
                        )}

                        {form.show && (
                          <div className="d-flex justify-content-center">
                            <div className="off-input">
                              <input
                                placeholder="کد تخفیف خود را وارد نمایید"
                                onChange={({ target: { value } }) => {
                                  setForm({ ...form, off_code: value });
                                }}
                              />
                              <button onClick={submitOff} disabled={loadingOff}>
                                {loadingOff ? (
                                  <div
                                    className="spinner-border spinner-border-sm text-light"
                                    role="status"
                                  ></div>
                                ) : (
                                  <img
                                    src={
                                      window.origin +
                                      "/assets/img/tick-square.svg"
                                    }
                                  />
                                )}
                              </button>
                            </div>
                          </div>
                        )}

                        <div className="join-course-box-sign-in row">
                          <div className="col-sm-6 mb-2 mb-lg-0">
                            <div className="join-input ">
                              <input
                                placeholder="نام و نام خانوادگی "
                                onChange={({ target: { value } }) => {
                                  setForm({ ...form, fullname: value });
                                }}
                                defaultValue={state?.user?.user?.fullname}
                              />
                              <div type="button" data-target="#passModal">
                                <img
                                  style={{ width: "100%" }}
                                  className="icon-image"
                                  src={
                                    window.origin +
                                    "/assets/img/broken/personalcard.svg"
                                  }
                                  alt="نام"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <div className="join-input ">
                              <input
                                placeholder="شماره موبایل"
                                onChange={({ target: { value } }) => {
                                  setForm({ ...form, mobile: value });
                                }}
                                defaultValue={state?.user?.user?.mobile}
                                disabled={state?.user?.user?.mobile}
                              />
                              <div type="button" data-target="#passModal">
                                <img
                                  className="icon-image"
                                  src={
                                    window.origin +
                                    "/assets/img/broken/mobile.svg"
                                  }
                                  alt="نام"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="d-flex justify-content-center">
                              <button
                                onClick={submitOrder}
                                disabled={loadingBtn}
                              >
                                {loadingBtn ? (
                                  <div
                                    className="spinner-border spinner-border-sm text-light"
                                    role="status"
                                  ></div>
                                ) : (
                                  "ثبت‌نام در دوره"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Comments
              ownerClassName="mt-27"
              relID={1}
              relType="cource_id"
              data={data.comments}
              rate={true}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Single;
