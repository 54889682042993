import { useEffect, useState } from "react";
import { useMainContext } from "../../../Context";
import { commentstore, commentreply } from "../../../Utils/Apis";

export const useComment = () => {
  const { state, post } = useMainContext();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState();
  const sendComment = async (relid, reltype, rate) => {
    setLoading(true);
    await post(
      commentstore,
      {
        text,
        [reltype]: relid,
        rate,
      },
      1
    );
    setText("");
    setLoading(false);
  };
  const sendReply = async (relid, reltype) => {
    setLoading(true);
    await post(
      commentreply,
      {
        text,
        [reltype]: relid,
      },
      1
    );
    setText("");
    setLoading(false);
  };
  return {
    loading,
    state,
    sendReply,
    sendComment,
    setText,
    text,
  };
};
