import React from "react";
import ImageProfileUploader from "../../Components/ImageProfileUploader";
import { useProfile } from "./Hooks";

const Profile = () => {
  const { form, setForm, updateImageProfile, state, loading, updateProfile } =
    useProfile();
  return (
    <div id="main-content" className="col-lg-9">
      <div className="user-info container">
        {/* User Title */}
        <div className="info-title d-flex">
          <img src="assets/img/broken/barcode.svg" alt="/" />
          <p className="mb-0 text-white fs-16">حساب کاربری</p>
        </div>
        <div className="info-img text-center mb-19">
          <span className="d-block text-blue mt-3 fs-18 fw-semi-normal">
            اطلاعات زیر جهت شناسایی و پیگیری سفارشات شما استفاده می‌شود. لطفا
            اطلاعات رو به صورت صحیح و کامل وارد نمایید.
          </span>
        </div>
        <ImageProfileUploader
          preview={state?.user?.user?.photo && state?.user?.user?.photo[0]}
          onChange={files => {
            updateImageProfile(files[0]);
          }}
          loading={loading.imageProfile}
        />
        {/*Info Img*/}

        {/*Information*/}
        <div className="info row justify-content-center">
          {/* Name */}
          <div className="col-lg-10 mt-2">
            <div className="d-flex justify-content-between">
              <div className="indo-details">
                <span className="text-blue fs-16 fw-semi-normal">
                  {" "}
                  نام و نام خانوادگی:
                </span>
                <input
                  className="mb-0 d-inline-block text-blue fs-16 input-profile"
                  onChange={({ target: { value } }) => {
                    setForm({ ...form, fullanem: value });
                  }}
                  Value={state?.user?.user?.fullname}
                />
              </div>
              <button
                className="btn-modal"
                type="button"
                data-target="#passModal"
              >
                <img
                  className="icon-image"
                  src={window.origin + "/assets/img/broken/personalcard.svg"}
                  alt="نام و نام خانوادگی"
                />
              </button>
            </div>
          </div>
          {/* Last Name */}
          {/* Phone */}
          <div className="col-lg-5 col-sm-6 mt-2">
            <div className="d-flex justify-content-between">
              <div className="indo-details">
                <span className="text-blue fs-16 fw-semi-normal">
                  شماره تماس:
                </span>
                <input
                  className="mb-0 d-inline-block text-blue fs-16 input-profile"
                  onChange={({ target: { value } }) => {
                    setForm({ ...form, mobile: value });
                  }}
                  disabled={true}
                  defaultValue={state?.user?.user?.mobile}
                />
              </div>
              <div className="info-btn">
                <button
                  className="btn-modal"
                  type="button"
                  data-target="#passModal"
                >
                  <img
                    className="icon-image"
                    src={window.origin + "/assets/img/broken/mobile.svg"}
                    alt="نام"
                  />
                </button>
              </div>
            </div>
          </div>
          {/* Password */}
          <div className="col-lg-5 col-sm-6 mt-2">
            <div className="d-flex justify-content-between">
              <div className="indo-details">
                <span className="text-blue fs-16">ایمیل:</span>
                <input
                  className="mb-0 d-inline-block text-blue fs-16 input-profile"
                  onChange={({ target: { value } }) => {
                    setForm({ ...form, email: value });
                  }}
                  defaultValue={state?.user?.user?.email}
                />
              </div>
              <div className="info-btn">
                <button
                  className="btn-modal"
                  type="button"
                  data-target="#passModal"
                >
                  <img
                    className="icon-image"
                    src={window.origin + "/assets/img/broken/sms.svg"}
                    alt="نام"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mb-3">
          <button className="save-btn" onClick={updateProfile}>
            {loading.profile ? (
              <>
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                ></div>
              </>
            ) : (
              <>ذخیره تغییرات</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
