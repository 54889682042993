import React from "react";
import FlatList from "../../Components/FlatList";
import FreeMoshavereh from "../../Components/FreeMoshavereh";
import Loading from "../../Components/Loading";
import { useContactUs } from "./Hooks";

const ContactUs = () => {
  const { data, loading } = useContactUs();
  if (loading) {
    return (
      <div className="main ">
        <section>
          <div className="container mt-9">
            <div className="row justify-content-center">
              <div className="col mb-30">
                <Loading height={"390"} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
  return (
    <div className="main ">
      <section>
        <div className="container mt-9">
          <div
            className="contact-us-section  "
            style={{
              minHeight: "50vh",
              display: "flex",
              alignItems: "stretch",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="row justify-content-center "
              style={{ marginBottom: "45px" }}
            >
              <div className="col-md-8 ">
                <div className="d-flex justify-content-center">
                  <h1 className="contact-us-title">تماس با ما</h1>
                </div>
                <p className="subtitle">
                  شما همراه گرامی خانواده نیما کوچکی از طریق این روش‌ها میتونید
                  با ما در ارتباط باشید.
                </p>
                <div className="row justify-content-center">
                  <FlatList
                    data={[
                      {
                        icon: () => {
                          return <span className="icon-Phone-2" />;
                        },
                        label: data.supportnumber,
                        url: "tel:" + data.supportnumber,
                      },
                      {
                        icon: () => {
                          return <span className="icon-Instagram-blue" />;
                        },
                        label: data.instagram,
                        url: "http://www.instagram.com/" + data.instagram,
                      },
                      {
                        icon: () => {
                          return <span className="icon-Telegram-blue" />;
                        },
                        label: data.telegram,
                        url: "https://t.me/" + data.telegram,
                      },
                      {
                        icon: () => {
                          return <span className="icon-Whatsapp-blue" />;
                        },
                        label: data.whatsapp,
                        url: "https://wa.me/+98" + data.whatsapp,
                      },
                    ]}
                    renderItem={(item, count) => {
                      return (
                        <a
                          className="col-lg-3 col-5 mb-lg-0 mb-3"
                          key={count}
                          href={item.url}
                        >
                          <div className="social-media-card">
                            <div>{item.icon()}</div>
                            <span>{item.label}</span>
                          </div>
                        </a>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <FreeMoshavereh />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
