import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useMainContext } from "../../../Context";
import { freemosavere } from "../../../Utils/Apis";

export const useFreeMosahvere = () => {
  const {
    post,
    state: { user },
  } = useMainContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    mobile: user?.user?.mobile || "",
    fullname: user?.user?.fullname || "",
  });
  const sendData = async () => {
    setLoading(true);
    await post(
      freemosavere,
      {
        fullname: data.fullname,
        mobile: data.mobile,
      },
      1
    );
    setLoading(false);
  };
  return {
    setData,
    sendData,
    data,
    loading,
  };
};
