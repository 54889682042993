import React from "react";
import { useBlog } from "./Hooks";
import { Helmet } from "react-helmet";
import Comments from "../../Components/Comments";
import Loading from "../../Components/Loading";

const Single = () => {
  const { loading, data } = useBlog();
  if (loading) {
    return (
      <div className="main">
        <section id="blog-single">
          <div className=" container mt-9">
            <div className="container">
              <Loading height={"512px"} />
            </div>
          </div>
        </section>
        <section id="blog-single-content" className="pt-38 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Loading />
              </div>
            </div>
          </div>
        </section>
        <section className="comments">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <Loading count={3} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{`${data?.blog?.title} | نیما کوچکی`}</title>
        <meta
          name="description"
          content={data?.blog?.seo?.seoDescription || data.blog.description}
        />
      </Helmet>
      <div className="main">
        <section id="blog-single">
          <div className=" container mt-8">
            <div className="blog-single-hero-img mt-20 ">
              <img
                className="br-20"
                src={
                  // (data?.blog?.image[0] && data?.blog?.image[0].url) ||
                  window.origin + "/assets/img/blog/item-1.jpg"
                }
                alt={
                  (data?.blog?.image[0] && data?.blog?.image[0].alt) ||
                  data.blog.title
                }
              />
              <div className="blog-single-header-content ">
                <div className="row">
                  <div className="col-6 text-start align-items-center d-flex">
                    <h5 className="text-white mb-0 fs-20 fw-bold">
                      {data?.blog?.title}
                    </h5>
                  </div>
                  <div className="col-6 text-end">
                    <div className="blog-single-btn">
                      <a
                        href
                        className="fs-14"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <img
                          src={
                            window.origin + "/assets/img/broken/folder-open.svg"
                          }
                          style={{
                            width: "17px",
                            height: "16px",
                          }}
                          alt="/"
                        />
                        {data?.blog?.category?.title}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Blog-Single-Content */}
        <section id="blog-single-content" className="pt-38 ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="content my-3 content-blog"
                  style={{
                    textAlign: "justify",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: data?.blog?.content,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
        <section className="comments">
          <div className="container">
            <Comments
              data={data.comments}
              relID={data?.blog?.id}
              relType="blog_id"
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default Single;
