import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import { useOredr } from "./Hooks";
import { ToDateJalali } from "../../Utils/functions";

const Orders = () => {
  const { data, loading } = useOredr();
  const renderstatus = status => {
    switch (status) {
      case "waittopey":
      case "abandoned":
        return {
          title: "پرداخت رها شده",
          status: "process",
        };
      case "success":
        return {
          title: "پرداخت موفق",
          status: "success",
        };
      case "error":
        return {
          title: "پرداخت ناموفق",
          status: "error",
        };
    }
  };
  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={10}>
            {Array(3)
              .fill(1)
              .map((_, d) => (
                <Loading count={1} height={"35"} key={d} />
              ))}
          </td>
        </tr>
      );
    } else {
      if (data.data.length == 0) {
        return (
          <tr style={{ height: "60px" }}>
            <td colSpan={10} style={{ textAlign: "center" }}>
              <span className="fs-16">سفارشی وجود ندارد!</span>
            </td>
          </tr>
        );
      } else {
        return data.data.map((item, count) => {
          return (
            <tr key={count}>
              <td className=" d-lg-table-cell d-none">
                <span className="fs-12 text-blue">{count + 1}</span>
              </td>
              <td className=" d-lg-table-cell d-none">
                <p className="fs-12 text-blue mb-0">{item.trackingcode}</p>
              </td>
              <td className=" d-lg-table-cell d-none">
                <p className="fs-12 text-blue mb-0 ">
                  {item?.payment?.trackingcode || "-----"}
                </p>
              </td>
              <td>
                <p className="fs-12 text-blue mb-0">
                  {ToDateJalali(item.created_at)}
                </p>
              </td>
              <td className="d-lg-table-cell d-none">
                <p className="fs-12 text-blue mb-0">{item.cource.title}</p>
              </td>
              <td>
                <p className="fs-12 text-blue mb-0">
                  <span
                    className={`${renderstatus(item.status)["status"]} ml-5`}
                  />
                  {renderstatus(item.status)["title"]}
                </p>
              </td>
              <td className="btn-td">
                <Link
                  type="button"
                  to={`${item.status == "success" ? item.trackingcode : ""}`}
                  className="btn-modal"
                >
                  <span
                    style={{ fontSize: "20px" }}
                    className={
                      item.status == "success"
                        ? "icon-Order-Detail"
                        : "icon-Order-Detail-disable"
                    }
                  />
                </Link>
              </td>
            </tr>
          );
        });
      }
    }
  };
  return (
    <div id="main-content" className="col-lg-9">
      <div className="user-info container">
        {/* User Title */}
        <div className="info-title d-flex">
          <img src="assets/img/broken/barcode.svg" alt="/" />
          <p className="mb-0 text-white fs-16">سفارشات من</p>
        </div>
        {/* Dashboard Content */}
        <div className="info-content">
          <div className="row">
            <div className="col-lg-12">
              <p
                className="text-blue mb-0 fs-18"
                style={{ textAlign: "justify" }}
              >
                لیست سفارشات شما به صورت زیر است. برای فعالسازی دوره‌های خود از
                قسمت جزئیات لایسنس خود را دریافت نمایید و در صورت وجود مشکل با
                پشتیبانان ما در ارتباط باشید. برای آموزش تحوه فعال‌سازی لایسنس{" "}
                <a
                  href="https://app.spotplayer.ir/player/help/"
                  className="text-yellow"
                >
                  اینجا
                </a>{" "}
                کلیک کنید.{" "}
              </p>
            </div>
          </div>
        </div>
        {/* Info Box */}
        <div className="info row overflow-auto mt-3 mb-3">
          <table>
            <thead>
              <tr>
                <th className="d-lg-table-cell d-lg-table-cell d-none">
                  <p className="fs-16 text-blue mb-0 fw-semi-normal">ردیف</p>
                </th>
                <th className="d-lg-table-cell d-lg-table-cell d-none">
                  <p className="fs-16 text-blue mb-0 fw-semi-normal">
                    شماره سفارش
                  </p>
                </th>
                <th className="d-lg-table-cell d-lg-table-cell d-none">
                  <p className="fs-16 text-blue mb-0 fw-semi-normal">
                    شماره تراکنش
                  </p>
                </th>
                <th>
                  <p className="fs-16 text-blue mb-0 fw-semi-normal">
                    تاریخ ثبت
                  </p>
                </th>
                <th className="d-lg-table-cell d-lg-table-cell d-none">
                  <p className="fs-16 text-blue mb-0 fw-semi-normal">
                    نوع محصول
                  </p>
                </th>
                <th>
                  <p className="fs-16 text-blue mb-0 fw-semi-normal">وضعیت</p>
                </th>
                <th>
                  <p className="fs-16 text-blue mb-0 fw-semi-normal">جزئیات</p>
                </th>
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Orders;
