import React from "react";
import { useFreeMosahvere } from "./Hooks";

const FreeMoshavereh = ({ title }) => {
  const { data, sendData, setData, loading } = useFreeMosahvere();
  return (
    <div className="row justify-content-center">
      <div className="col-lg-9">
        <div className="moshavere-box">
          {title ? (
            title()
          ) : (
            <>
              <h4>
                یه مشاوره <span>رایگان</span> مهمون ما باشید!
              </h4>
            </>
          )}

          <p>
            فقط کافیه اسم و شماره تماستون رو ثبت کنید و منتظر تماس مشاور باشید.
          </p>
          <div
            className=" row moshavere-box-form justify-content-between"
            style={{ flexWrap: "wrap", gap: "20px" }}
          >
            <div className=" col-sm-5 mb-lg-0 mb-2">
              <input
                placeholder="نام و نام خانوادگی"
                onChange={e => {
                  setData({ ...data, fullname: e.target.value });
                }}
              />
              <div type="button" data-target="#passModal">
                <img
                  style={{}}
                  className="icon-image"
                  src={window.origin + "/assets/img/broken/personalcard.svg"}
                  alt="نام"
                />
              </div>
            </div>
            <div className=" col-sm-5 mb-lg-0 mb-2">
              <input
                placeholder="شماره موبایل"
                onChange={e => {
                  setData({ ...data, mobile: e.target.value });
                }}
              />
              <div type="button" data-target="#passModal">
                <img
                  className="icon-image"
                  src={window.origin + "/assets/img/broken/mobile.svg"}
                  alt="نام"
                />
              </div>
            </div>
            <button className=" col-sm-2" onClick={() => sendData()}>
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                ></div>
              ) : (
                "ثبت اطلاعات"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeMoshavereh;
