import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import FreeMoshavereh from "../Components/FreeMoshavereh";
import Loading from "../Components/Loading";
import { useMainContext } from "../Context";
import { useOutsideHandler } from "../Hooks";
import { useSearch } from "./Hooks";
import $ from "jquery";

const Layout = ({ children }) => {
  const { isLogin, state } = useMainContext();
  const { form, toggle, getData, loading, data, setForm } = useSearch();
  const boxes = Array.from(document.getElementsByClassName("box"));
  const { pathname } = useLocation();
  console.log("p", pathname);
  const search = useRef(null);
  useOutsideHandler(search, () => {
    setForm({ show: false });
  });
  const ele = document?.getElementsByClassName("offcanvas-backdrop");

  useEffect(() => {
    $(function () {
      var navLink = $(".offcanvas-backdrop");
      console.log("b", navLink);
      navLink.hide();
    });
  }, [pathname, ele]);

  const renderModal = () => {
    if (loading) {
      return (
        <li>
          <Loading count={1} height="67" />
        </li>
      );
    } else {
      if (data.length == 0) {
        if (form.text) {
          return (
            <>
              <p className="text-center text-purple py-3">موردی یافت نشد</p>
            </>
          );
        }
      } else {
        return data.map((item, counter) => {
          if (counter < 3) {
            return (
              <>
                {" "}
                <li className={`search-result `}>
                  <Link
                    className={` dropdown-item ${
                      counter == 2 ? "border-bottom-0" : ""
                    }`}
                    to={
                      item.type == "cource"
                        ? "/hydrographics-course"
                        : `/blogs/${item.slug}`
                    }
                  >
                    <div>
                      <img
                        src={item?.image[0]?.url}
                        alt={item?.image[0]?.alt}
                      />
                      <h5 className="m-0 mr-15">{item?.title}</h5>
                    </div>
                    <span>
                      {item?.type == "blog" ? "مقاله" : "دوره آموزشی"}
                    </span>
                  </Link>
                </li>
              </>
            );
          }
        });
      }
    }
  };
  return (
    <>
      <div className="container">
        <div className="row align-items-center">
          {/* Site Header */}
          <header className="site-header sticky-bar stick">
            <nav className="navbar navbar-expand-lg position-relative">
              <div className="container ">
                <div className="col-lg-1 col-2 order-0">
                  <Link
                    className="navbar-brand d-block text-right me-0 w-70"
                    to="/"
                  >
                    {state.layoutData ? (
                      <img
                        src={
                          state.layoutData.logo ||
                          window.origin + "/assets/img/logo.png"
                        }
                        alt="LOGO"
                      />
                    ) : (
                      <Loading height={"40px"} />
                    )}
                  </Link>
                </div>
                <Responsive />
                <div className="col-lg-3 col-6 left-side">
                  <div className="d-flex justify-content-end">
                    <ul ref={search} className="list-inline d-flex mb-0">
                      <li className="list-inline-item">
                        <a onClick={toggle} className="search-btn" href>
                          <img
                            src={
                              window.origin +
                              "/assets/img/broken/search-normal-1.svg"
                            }
                            alt="Search"
                          />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          to={isLogin() ? "/dashboard" : "/login"}
                          className="btn fs-16"
                        >
                          {isLogin() ? "پنل کاربری" : "ورود / ثبت نام"}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </header>
          {/* Site Header */}
        </div>
      </div>

      {/* Footer */}

      {children}
      <footer id="footer" className="mb-4">
        <div className="container">
          <div className="footer-feature mt-6">
            <div className="row">
              <div className="col-md-9">
                {state.layoutData ? (
                  <p
                    className=" text-justify"
                    dangerouslySetInnerHTML={{
                      __html:
                        state.layoutData.aboutmetext ||
                        "من نیما کوچکی، بهت کمک میکنم تا با بروز ترین مهارت های شغلی دنیا که میتونه برای تو پولساز باشه آشنا بشی و اون مهارت رو بصورت کاملا تخصصی و حرفه‌ای یاد بگیری و ‌زودتر از هر شخص دیگه‌ای وارد اون کسب و کار بشی تا بتونی از دوران طلایی پولسازی توی اون مهارت لذت ببری.خیلی از اوقات این حسرت برای ما بوده که کاش زودتر با یک شغل یا مهارت آشنا میشدم تا بتونم جز اولین نفرها باشم.من بهت قول میدم که اگر با من همراه بشی همیشه اولین خواهی بود.",
                    }}
                  ></p>
                ) : (
                  <Loading />
                )}
              </div>
              <div className="col-md-3 text-end mt-2">
                <img
                  src={window.origin + "/assets/img/footer/footer-2.png"}
                  alt="آقای پرداخت"
                />
                <img
                  className="mr-10"
                  src={window.origin + "/assets/img/footer/footer-1.png"}
                  alt="نماد الکترونیک"
                />
              </div>
            </div>
          </div>
          <div className="copy-right">
            <div className="row">
              <div className="col-md-8 ">
                {state.layoutData ? (
                  <p className="text-white fs-16 mb-0 vertical-align-middle">
                    {state.layoutData.copyright ||
                      "تمامی حقوق این‌ سایت برای نیما کوچکی محفوظ است."}
                  </p>
                ) : (
                  <div className="row">
                    <Loading col={9} height={"20px"} />
                  </div>
                )}
              </div>
              <div className="col-md-4 d-none d-md-flex text-end ">
                {state.layoutData ? (
                  <ul className="list-inline mb-0 vertical-align-middle">
                    <li className="list-inline-item">
                      <a href={state.layoutData.twitter}>
                        <span className="icon-Telegram middle" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={state.layoutData.instagram}>
                        <span className="icon-Instagram middle" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={state.layoutData.whatsapp}>
                        <span className="icon-Whatsapp middle" />
                      </a>
                    </li>
                  </ul>
                ) : (
                  <div className="row justify-content-end">
                    <Loading col={4} height={"20px"} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer */}
      <div
        className={`modal fade ${form.show && "show d-block"} show `}
        id="exampleModalCenter"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div ref={search}>
                <div className="search-navbar-box">
                  <input
                    onChange={({ target: { value } }) => {
                      if (value.length == 1 || value.length % 2 == 0) {
                        getData(value);
                        setForm({ ...form, text: value });
                      }
                    }}
                    placeholder="عبارت مورد نظر خود را جست و جو کنید ..."
                  />
                  <a className="" href onClick={toggle}>
                    <span className="icon-Search" />
                  </a>
                </div>
                <ul
                  className={` fs-16 text-white p-0 ${
                    (data.length > 0 || loading) && "show"
                  } `}
                  style={{
                    backgroundColor: "#EEF0FC",
                    marginTop: "12.5px",
                    // bordeRadius: "18px",
                    borderRadius: "18px",
                  }}
                >
                  {renderModal()}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
const Responsive = () => {
  const [form, setForm] = useState({});
  const nav = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    setForm({ nav: false });
  }, [pathname]);

  useOutsideHandler(nav, () => {
    setForm({ nav: false });
  });
  return (
    <div ref={nav} className="col-lg-8  order-first order-lg-0 text-start h-44">
      <div>
        <button
          onClick={() => {
            setForm({ ...form, nav: !form.nav });
          }}
          className="navbar-toggler"
          type="button"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={`collapse navbar-collapse ${form.nav && "show"}`}
          id="navbarSupportedContent"
        >
          <ul className=" navbar-nav me-auto mb-2 mb-lg-0 h-auto">
            <li className="nav-item">
              <Link
                className="nav-link active fs-16 text-white"
                aria-current="page"
                to="/"
              >
                صفحه اصلی
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link fs-16 text-white"
                to="/hydrographics-course"
              >
                دوره هیدروگرافیک
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link fs-16 text-white"
                to=""
                onClick={() => toast.info("خیلی زود در خدمتتون هستیم")}
              >
                آزمون شخصیت شناسی
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fs-16 text-white" to="/blogs">
                مقالات
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fs-16 text-white" to="/contact-us">
                تماس با ما
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
