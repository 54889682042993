import { createContext, useContext, useEffect, useState } from "react";
import { API_SERVER, post } from "../Server";
import { toast } from "react-toastify";
import { serialize } from "object-to-formdata";
import axios from "axios";
import { layoutData } from "../Utils/Apis";

const context = createContext();

const ContextProvider = ({ children }) => {
  const initialState = {
    user:
      typeof window === "undefined"
        ? {}
        : JSON.parse(localStorage.getItem("user")) || {},
  };
  const [state, setState] = useState(initialState);
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("s", user);
  if (user && user.access_token) {
    axios.defaults.headers.post[
      "Authorization"
    ] = `Bearer ${user.access_token}`;
  }
  const server = axios.create({
    baseURL: API_SERVER,
  });
  useEffect(() => {
    if (state.layoutData) return;
    getLayoutData();
  }, []);
  const getLayoutData = async () => {
    const { data } = await post(layoutData);
    setState({ ...state, layoutData: data });
  };
  const post = async (url, body, message = false) => {
    try {
      const response = await server.post(url, serialize(body));
      if (message && response.data.message) {
        toast.success(response.data.message);
      }
      return {
        data: response.data.data,
        status: response.status,
      };
    } catch (error) {
      const {
        response: { status, data },
      } = error;
      switch (+status) {
        case 400:
          toast.error(data.message);
          break;
        case 401:
          localStorage.clear();
          window.location.replace("/");
          toast.error(data.message);
          break;
        case 500:
          toast.error(data.message);
        default:
          break;
      }
      return {};
    }
  };
  const dispatch = (key, value) => {
    if (value) {
      setState({ ...state, [key]: value });
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      const newState = { ...state };
      delete newState[key];
      setState({ newState });
      localStorage.removeItem(key);
    }
  };

  const isLogin = () => {
    if (state?.user?.access_token) return true;
    return false;
  };
  const logout = async () => {
    setState({ ...state, loadingLogOut: true });
    const { data, status } = await post("/logout");
    if (+status === 200) {
      dispatch("user");
      window.location.replace("/");
      setState({ ...state, loadingLogOut: false });
    }
  };
  return (
    <context.Provider value={{ state, dispatch, isLogin, logout, post }}>
      {children}
    </context.Provider>
  );
};

export default ContextProvider;
export const useMainContext = () => {
  return useContext(context);
};
