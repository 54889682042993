export const login = "/check";
export const verfiyCode = "/validation";
export const logout = "/logout";
export const editProfile = "/profile/edit";
export const blogs = "/blog/weblist";
export const blog = "/blog/single";
export const search = "/search";
export const freemosavere = "/moshavereh/store";
export const contactus = "/connectus";
export const layoutData = "/layout_data";
export const landing = "/landing";
export const singlecource = "/cource/single";
export const commentstore = "/comment/store";
export const commentreply = "/comment/reply";
export const dashboard = "/user/dashboard";
export const orderlist = "/order/userlist";
export const orderdetails = "/order/userdetails";
export const newOrder = "/order/new";
export const offCheck = "/off/check";

// off/check
