import React, { useState } from "react";
import { timeSince } from "../../Utils/functions";
import Paginate from "../Paginate";
import { useComment } from "./Hooks";

const Comments = ({
  data = Array(2).fill(1),
  relID,
  relType,
  ownerClassName,
  rate = false,
}) => {
  console.log({ relID, relType });
  const { loading, state, sendReply, sendComment, setText, text } =
    useComment();
  const [form, setForm] = useState({ rate: false });
  const newcommect = () => {
    if (data.unkhown == "true") {
      return (
        <>
          <div className="d-flex align-items-center">
            <input
              type={"text"}
              placeholder="نظر شما چیه؟"
              onChange={e => setText(e.target.value)}
              value={text}
              onKeyDown={e => {
                if (e.key == "Enter") {
                  sendComment(relID, relType, form.rate);
                }
              }}
            />
            {rate && <RateRender form={form} setForm={setForm} />}
          </div>

          <button onClick={() => sendComment(relID, relType, form.rate)}>
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            ) : (
              <span
                className="icon-Send"
                style={{ verticalAlign: "middle" }}
              ></span>
            )}
          </button>
        </>
      );
    } else {
      if (state?.user?.access_token) {
        return (
          <>
            {" "}
            <div className="d-flex align-items-center">
              <input
                type={"text"}
                placeholder="نظر شما چیه؟"
                onChange={e => setText(e.target.value)}
                value={text}
                onKeyDown={e => {
                  if (e.key == "Enter") {
                    sendComment(relID, relType, form.rate);
                  }
                }}
              />
              {rate && <RateRender form={form} setForm={setForm} />}
            </div>
            <button onClick={() => sendComment(relID, relType, form.rate)}>
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                ></div>
              ) : (
                <span
                  className="icon-Send"
                  style={{ verticalAlign: "middle" }}
                ></span>
              )}
            </button>
          </>
        );
      } else {
        return (
          <>
            <input
              type={"text"}
              placeholder="نظر شما چیه؟"
              onChange={e => setText(e.target.value)}
              value={text}
              onKeyDown={e => {
                if (e.key == "Enter") {
                  sendComment(relID, relType, form.rate);
                }
              }}
            />
            <button onClick={() => sendComment(relID, relType, form.rate)}>
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                ></div>
              ) : (
                <span
                  className="icon-Send"
                  style={{ verticalAlign: "middle" }}
                ></span>
              )}
            </button>
            <div className="register-user">
              <p>برای ثبت نظر ابتدا باید وارد حساب کاربری خود شوید</p>
            </div>
          </>
        );
      }
    }
  };
  return (
    <div className="row justify-content-center mt-comment">
      <div className="col-lg-8">
        {" "}
        <div className="comments">
          <div className="d-flex justify-content-center align-items-center">
            <h2 className="">
              نظر کاربران <span>{data?.data.length}</span>
            </h2>
          </div>

          {data?.data.map(comment => {
            return (
              <div className="comment">
                <div className="comment-info">
                  <div className="d-flex align-items-center">
                    <img
                      src={comment?.commenter?.photo || data?.defaultimage}
                      alt={comment?.commenter?.fullname}
                    />
                    <h5 className="mr-10">{comment?.commenter?.fullname}</h5>
                  </div>
                  <span>{timeSince(comment?.created_at)}</span>
                </div>
                <div className="comment-content">
                  <p>{comment?.text}</p>
                </div>
                {comment?.reply.length > 0 ? (
                  comment.reply.map(rep => {
                    return (
                      <div className="p-3">
                        <div className="comment-info">
                          <div className="d-flex align-items-center">
                            <img
                              src={rep?.commenter?.photo || data?.defaultimage}
                              alt={rep?.commenter?.fullname}
                            />
                            <h5 className="mr-10">
                              {rep?.commenter?.fullname}
                            </h5>
                            <div
                              className="mr-10"
                              style={{
                                backgroundColor: "#EEF0FC",
                                borderRadius: "10px",
                                padding: "0px 10px",
                              }}
                            >
                              <span>مدیر سایت</span>
                            </div>
                          </div>
                          <span>{timeSince(rep?.created_at)}</span>
                        </div>
                        <div className="comment-content">
                          <p>{rep?.text}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            );
          })}
          {data?.data.length > 0 && (
            <div
              className={`d-flex align-items-center justify-content-center  ${ownerClassName}`}
            >
              <Paginate active={data?.current_page} pages={data?.links} />
            </div>
          )}

          <div className="send-comment ">
            <div className="comment-wrapper">{newcommect()}</div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Comments;
const RateRender = ({ form, setForm, keyForm = "rate" }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div className="rate-render flex-row-reverse">
      {Array(5)
        .fill(1)
        .map((_i, counter) => {
          return (
            <>
              <span
                onMouseEnter={() => {
                  setHovered(counter + 1);
                }}
                onMouseLeave={() => {
                  setHovered(false);
                }}
                onClick={() => {
                  setForm({ ...form, [keyForm]: counter + 1 });
                }}
                key={counter}
                className="icon-Star-disable fs-20"
                data-status={
                  hovered >= counter + 1 || form[keyForm] >= counter + 1
                }
              ></span>
            </>
          );
        })}
    </div>
  );
};
