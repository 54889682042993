import { useEffect, useState } from "react";
import { useMainContext } from "../../../Context";
import { dashboard } from "../../../Utils/Apis";

export const useDashboard = () => {
  const { post } = useMainContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { user } = useMainContext();
  const getData = async () => {
    setLoading(true);
    const { data } = await post(dashboard);
    setData(data || []);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return {
    loading,
    data,
    user,
  };
};
