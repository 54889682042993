import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import { useMainContext } from "../Context";
import { useOutsideHandler } from "../Hooks";
import $ from "jquery";
import { useSearch } from "./Hooks";
const PanelLayout = ({ children }) => {
  const { pathname } = useLocation();
  const { state, isLogin, logout } = useMainContext();
  const { form, toggle, getData, loading, data, setForm } = useSearch();
  const search = useRef(null);
  const [show, setShow] = useState(false);
  const [drawer, setdrawer] = useState(false);
  useEffect(() => {
    setdrawer(false);
  }, [pathname]);

  useOutsideHandler(search, () => {
    setForm({ show: false });
  });
  const navigate = useNavigate();

  useEffect(() => {
    $(function () {
      // var navLink = $(".offcanvas-backdrop");
      // var offcanvasRight = $("#offcanvasRight");
      // navLink.hide();
      // offcanvasRight.hide();
    });
  }, [pathname]);
  const renderModal = () => {
    if (loading) {
      return (
        <li>
          <Loading count={1} height="67" />
        </li>
      );
    } else {
      if (data.length == 0) {
        if (form.text) {
          return (
            <>
              <p className="text-center text-purple py-3">موردی یافت نشد</p>
            </>
          );
        }
      } else {
        return data.map((item, counter) => {
          if (counter < 3) {
            return (
              <>
                {" "}
                <li className={`search-result `}>
                  <Link
                    className={` dropdown-item ${
                      counter == 2 ? "border-bottom-0" : ""
                    }`}
                    to={
                      item.type == "cource"
                        ? "/hydrographics-course"
                        : `/blogs/${item.slug}`
                    }>
                    <div>
                      <img
                        src={item?.image[0]?.url}
                        alt={item?.image[0]?.alt}
                      />
                      <h5 className="m-0 mr-15">{item?.title}</h5>
                    </div>
                    <span>
                      {item?.type == "blog" ? "مقاله" : "دوره آموزشی"}
                    </span>
                  </Link>
                </li>
              </>
            );
          }
        });
      }
    }
  };
  if (!isLogin()) {
    window.location.replace("/login");

    return <></>;
  }
  return (
    <>
      <section>
        <header
          className="site-header menu-header "
          style={{ zIndex: "1340", position: "relative" }}>
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <div className="col-lg-1 col-2 order-0">
                <Link
                  to={"/"}
                  className="navbar-brand d-none d-lg-block"
                  href="#">
                  {state.layoutData ? (
                    <img src={state.layoutData.logo} alt="LOGO" />
                  ) : (
                    <Loading height={"40px"} />
                  )}
                </Link>
              </div>
              <div className="col-lg-8 col-4 order-first order-lg-0">
                <div className="d-none d-lg-block">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent">
                    <ul className=" navbar-nav me-auto mb-2 mb-lg-0 h-auto">
                      <li className="nav-item">
                        <Link
                          className="nav-link active fs-16 text-white"
                          aria-current="page"
                          to="/">
                          صفحه اصلی
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link fs-16 text-white"
                          to="/hydrographics-course">
                          دوره هیدروگرافیک
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link fs-16 text-white"
                          to=""
                          onClick={() => toast("خیلی زود در خدمتتون هستیم")}>
                          آزمون شخصیت شناسی
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link fs-16 text-white" to="/blogs">
                          مقالات
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link fs-16 text-white"
                          to="/contact-us">
                          تماس با ما
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="category-btn d-lg-none">
                  <button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => {
                      setdrawer(!drawer);
                    }}
                    // onClick={() => {
                    //   setForm({ ...form, drawer: !form.drawer });
                    // }}
                    style={{
                      width: "37px",
                      height: "37px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    {drawer ? (
                      <span
                        className="icon-MenuQuit"
                        style={{ fontSize: "11.32px" }}
                      />
                    ) : (
                      <img
                        src={window.origin + "/assets/img/broken/menu-1.svg"}
                        alt="آیکون رسپانسیو"
                      />
                    )}
                  </button>
                </div>
              </div>
              <div className="col-lg-3 col-6 left-side">
                <div className="d-flex justify-content-end">
                  <ul className="list-inline d-flex mb-0">
                    <li className="list-inline-item ">
                      {state.layoutData ? (
                        <>
                          <span className="navbar-color fs-16 fw-500">
                            <a href={`tel:${state.layoutData.supportnumber}`}>
                              شماره‌تماس: {state.layoutData.supportnumber}
                            </a>
                          </span>
                        </>
                      ) : (
                        <>
                          <Loading height={"40px"} />
                        </>
                      )}
                    </li>
                    <li className="list-inline-item">
                      <a onClick={toggle} className="search-btn" href>
                        <img
                          src={
                            window.origin +
                            "/assets/img/broken/search-normal-1.svg"
                          }
                          alt="Search"
                        />
                      </a>
                    </li>

                    {/* <li className="list-inline-item d-none d-lg-block">
                      <Link
                        to={isLogin() ? "/dashboard" : "/login"}
                        className="btn fs-16"
                      >
                        {isLogin() ? "پنل کاربری" : "ورود / ثبت نام"}
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </section>

      <div className="main" id="dashboard">
        <section id="portfolio" className="">
          <div className="container">
            <div className="row">
              <aside className="sidenav col-lg-3 d-none d-lg-block">
                <div>
                  <div className="menu-info text-center">
                    <div className="info-img mx-auto d-flex align-items-center justify-content-center">
                      {state?.user?.user?.photo ? (
                        <img
                          src={
                            state?.user?.user?.photo
                              ? state?.user?.user?.photo[0]
                              : "/assets/img/broken/frame-2.svg"
                          }
                          alt="IMG"
                        />
                      ) : (
                        <img
                          src={"/assets/img/broken/frame-2.svg"}
                          alt="IMG"
                          style={{ width: "24px", height: "24px" }}
                        />
                      )}
                    </div>
                    <div className="info-detail">
                      <p className="text-blue  mb-2 mt-2">
                        {`${state?.user?.user?.fullname ?? "کاربر"} عزیز`}
                      </p>
                      <span className="text-purple ">
                        {state?.user?.user?.mobile}
                      </span>
                    </div>
                  </div>
                  {/* Site Info */}
                  {/* Profile Menu */}
                  <div className="profile-menu">
                    {/* top link */}
                    <ul className="list-unstyled profile-list">
                      <li className={pathname == "/dashboard" ? "active" : ""}>
                        <Link to="/dashboard">
                          {pathname == "/dashboard" ? (
                            <>
                              <span className="icon-Dashboard-Active">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                                <span className="path4" />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="icon-Asset-3" />
                            </>
                          )}
                          <p className="mb-0 ">پیـشـخـوان</p>
                        </Link>
                      </li>
                      <li className={pathname == "/orders" ? "active" : ""}>
                        <Link to={"/orders"}>
                          {pathname == "/orders" ? (
                            <>
                              <span className="icon-Orders-Active">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                                <span className="path4" />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="icon-Orders" />
                            </>
                          )}
                          <p className="mb-0 ">سفارش‌های من</p>
                        </Link>
                      </li>
                      <li className={pathname == "/profile" ? "active" : ""}>
                        <Link to={"/profile"}>
                          {pathname == "/profile" ? (
                            <>
                              <span className="icon-Profile-Active">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                                <span className="path4" />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="icon-Profile" />
                            </>
                          )}
                          <p className="mb-0 ">حساب کاربری</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="bottom-link">
                    <a onClick={logout} style={{ cursor: "pointer" }}>
                      {state.loadingLogOut ? (
                        <>
                          <div
                            className="spinner-border spinner-border-sm text-dark"
                            role="status"
                          ></div>
                        </>
                      ) : (
                        <>
                          <img
                            src={
                              window.origin + "/assets/img/broken/logout-1.svg"
                            }
                            alt
                          />
                          <p className="text-red  mb-0">خروج از حساب کاربری</p>
                        </>
                      )}
                    </a>
                  </div>
                  {/* Profile Menu */}
                </div>
              </aside>
              {children}
            </div>
          </div>
        </section>
      </div>

      <footer id="footer" className="mb-4 footer-panel" style={{}}>
        <div className="container">
          <div className="copy-right">
            <div className="row">
              <div className="col-md-8 ">
                {state.layoutData ? (
                  <p className="text-white fs-16 mb-0 vertical-align-middle">
                    {state.layoutData.copyright ||
                      "تمامی حقوق این‌ سایت برای نیما کوچکی محفوظ است."}
                  </p>
                ) : (
                  <div className="row">
                    <Loading col={9} height={"20px"} />
                  </div>
                )}
              </div>
              <div className="col-md-4 d-none d-sm-flex text-end ">
                {state.layoutData ? (
                  <ul className="list-inline mb-0 vertical-align-middle">
                    <li className="list-inline-item">
                      <a href={state.layoutData.twitter}>
                        <span className="icon-Telegram middle" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={state.layoutData.instagram}>
                        <span className="icon-Instagram middle" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={state.layoutData.whatsapp}>
                        <span className="icon-Whatsapp middle" />
                      </a>
                    </li>
                  </ul>
                ) : (
                  <div className="row justify-content-end">
                    <Loading col={4} height={"20px"} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div
        className={`modal fade ${form.show && "show d-block"} show `}
        id="exampleModalCenter"
        tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div ref={search}>
                <div className="search-navbar-box">
                  <input
                    onChange={({ target: { value } }) => {
                      if (value.length == 1 || value.length % 2 == 0) {
                        getData(value);
                        setForm({ ...form, text: value });
                      }
                    }}
                    placeholder="عبارت مورد نظر خود را جست و جو کنید ..."
                  />
                  <a className="" href onClick={toggle}>
                    <span className="icon-Search" />
                  </a>
                </div>
                <ul
                  className={` fs-16 text-white p-0 ${
                    (data.length > 0 || loading) && "show"
                  } `}
                  style={{
                    backgroundColor: "#EEF0FC",
                    marginTop: "12.5px",
                    // bordeRadius: "18px",
                    borderRadius: "18px",
                  }}>
                  {renderModal()}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas> */}
      <Offcanvas
        show={drawer}
        onHide={() => {
          setdrawer(false);
        }}
        placement={"end"}>
        <div className="show-menu-up-drawer">
          {window.innerWidth > 768 ? (
            <>
              <ul className="">
                <li className="">
                  <Link
                    className="nav-link active fs-16 text-white"
                    aria-current="page"
                    to="/">
                    صفحه اصلی
                  </Link>
                </li>
                <li className="">
                  <Link
                    className="nav-link fs-16 text-white"
                    to="/hydrographics-course">
                    دوره هیدروگرافیک
                  </Link>
                </li>
                <li className="">
                  <Link
                    className="nav-link fs-16 text-white"
                    to=""
                    onClick={() => toast("خیلی زود در خدمتتون هستیم")}>
                    آزمون شخصیت شناسی
                  </Link>
                </li>
                <li className="">
                  <Link className="nav-link fs-16 text-white" to="/blogs">
                    مقالات
                  </Link>
                </li>
                <li className="">
                  <Link className="nav-link fs-16 text-white" to="/contact-us">
                    تماس با ما
                  </Link>
                </li>
              </ul>
            </>
          ) : (
            <>
              <ul className="">
                <li className="">
                  <Link
                    className="nav-link active fs-16 text-white"
                    aria-current="page"
                    to="/">
                    صفحه اصلی
                  </Link>
                </li>
                <li className="">
                  <Link
                    className="nav-link fs-16 text-white"
                    to="/hydrographics-course">
                    دوره هیدروگرافیک
                  </Link>
                </li>

                <Dropdown align={{ lg: "end" }} className="other-dropdown">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    سایر
                  </Dropdown.Toggle>

                  <Dropdown.Menu dir="ltr" className="other-wrap">
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/blogs");
                      }}>
                      <Link className="nav-link fs-16 text-white" to="/blogs">
                        مقالات
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/contact-us");
                      }}>
                      <Link
                        className="nav-link fs-16 text-white"
                        to="/contact-us">
                        تماس با ما
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </>
          )}
          {/* */}
        </div>
        <div className="sidenav ">
          <div className="">
            {/* Site Info */}
            <div className="menu-info text-center">
              <div className="info-img mx-auto d-flex align-items-center justify-content-center">
                {state?.user?.user?.photo ? (
                  <img
                    src={
                      state?.user?.user?.photo
                        ? state?.user?.user?.photo[0]
                        : "/assets/img/broken/frame-2.svg"
                    }
                    alt="IMG"
                  />
                ) : (
                  <img
                    src={"/assets/img/broken/frame-2.svg"}
                    alt="IMG"
                    style={{ width: "24px", height: "24px" }}
                  />
                )}
              </div>
              <div className="info-detail">
                <p className="text-blue fs-16 mb-0 mt-3">
                  {`${state?.user?.user?.fullname ?? "کاربر"} عزیز`}
                </p>
                <span className="text-purple fs-16">
                  {state?.user?.user?.mobile}
                </span>
              </div>
            </div>
            {/* Site Info */}
            {/* Profile Menu */}
            <div className="profile-menu">
              {/* top link */}
              <ul className="list-unstyled profile-list">
                <li className={pathname == "/dashboard" ? "active" : ""}>
                  <Link to={"/dashboard"}>
                    {pathname == "/dashboard" ? (
                      <>
                        <span className="icon-Dashboard-Active">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="icon-Asset-3" />
                      </>
                    )}
                    <p className="mb-0 text-blue">پیـشـخـوان</p>
                  </Link>
                </li>
                <li className={pathname == "/orders" ? "active" : ""}>
                  <Link to={"/orders"}>
                    {pathname == "/orders" ? (
                      <>
                        <span className="icon-Orders-Active">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="icon-Orders" />
                      </>
                    )}
                    <p className="mb-0 text-purple">سفارش‌های من</p>
                  </Link>
                </li>
                <li className={pathname == "/profile" ? "active" : ""}>
                  <Link to={"/profile"}>
                    {pathname == "/profile" ? (
                      <>
                        <span className="icon-Profile-Active">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="icon-Profile" />
                      </>
                    )}
                    <p className="mb-0 text-purple">حساب کاربری</p>
                  </Link>
                </li>
              </ul>
              {/* ./ top link */}
              {/* bottom link */}
            </div>
            {/* Profile Menu */}
            <div className="bottom-link">
              <a onClick={logout} style={{ cursor: "pointer" }}>
                {state.loadingLogOut ? (
                  <>
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>
                    <img
                      src={window.origin + "/assets/img/broken/logout-1.svg"}
                      alt
                    />
                    <p className="text-red  mb-0">خروج از حساب کاربری</p>
                  </>
                )}
              </a>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default PanelLayout;
