import React from "react";

const NotFound = () => {
  return (
    <div className="main">
      <section id="error-404" style={{ height: "56vh" }}>
        <div className="container">
          <div className="d-grid align-items-center justify-content-center mt-9">
            <div className="hero-access-denied mt-7 d-flex justify-content-center">
              <img
                src="assets/img/access-denied/404.png"
                style={{ width: "437px", height: "255px" }}
                alt={404}
              />
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-center mt-3">
                <p className="mb-0 text-blue-pr">
                  متاسفانه صفحه ای که به دنبال آن میگردید یافت نشد
                </p>
                <p className="mb-0 text-blue-pr">
                  (در صورت نیاز با مدیر سایت در ارتباط باشید)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
