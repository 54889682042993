import React from "react";

const FlatList = ({
  renderItem = () => {},
  data = Array(9).fill(1),
  renderEmpty = () => {
    return (
      <div className="mb-5">
        <h5 className="text-center">مقاله ایی یافت نشد</h5>
      </div>
    );
  },
}) => {
  if (data.length === 0) {
    return renderEmpty();
  }
  return data.map((item, count) => {
    return renderItem(item, count);
  });
};

export default FlatList;
