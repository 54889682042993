import React from "react";
import { p2e } from "../../Utils/functions";
import { useLogin } from "./Hooks";

const Login = () => {
  const { form, setForm, submit, loading } = useLogin();

  return (
    <div className="main" id="Register-page">
      <div className="register-box col-12">
        <div className="logo-box text-center">
          <img src="assets/img/logo.png" alt="LOGO" />
        </div>
        <p className=" text-blue mt-4 text-center">
          شماره همراه خود را برای ورود و یا ثبت‌ نام وارد کنید
        </p>
        <div>
          <div className="input-form mt-4">
            <label htmlFor="number">
              <img src="assets/img/broken/call.svg" alt="tel" />
              <input
                type="tel"
                name="number"
                id="number"
                placeholder={"09120000000"}
                required
                autoFocus
                onChange={({ target: { value } }) => {
                  setForm({ mobile: p2e(value) });
                }}
                onKeyDown={e => {
                  if (e.key == "Enter") {
                    submit();
                  }
                }}
              />
            </label>
          </div>
          <button
            className="submit-btn"
            name="send"
            id="submit"
            disabled={loading || form.mobile.length !== 11}
            onClick={submit}
          >
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            ) : (
              "تایید"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
